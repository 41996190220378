:root {
    /* defcon's official theme colors -- official bg is #111? */
    --dc-yellow: #eec643;
    --dc-red:    #e25238;
    --dc-orange: #dc8530;
    --dc-blue:   #4999e5;  /* hmm... still kinda dark */
    --dc-green:  #64d576;
    --dc-text:   #ccc;  /* Based on the theme page */

    --dc-blue-ligther:  #a1cbf2; /* my ligther variation on dc's color */
    --dc-green-ligther: #8bdf99;
    --dc-red-darker: #701e10;

    /* our own theme, sort of */
    --ooo-dark: #222b2f;
    --ooo-gold: #caa851;
    --ooo-darkred: #6d1422;
    --ooo-red: #FF5769;  /* Light but not so light and, as always, a problem */
    --ooo-white: #eaeaea;

    --map-bg: #424146; /* From the big world map */
}

/* Conthrax and Futura are defcon's official theme fonts */
@font-face {
    /* Font (c) 2010-2021 Fontspring */
    font-family: 'conthraxsemibold';
    src: url('/fonts/conthrax-sb-webfont.woff2') format('woff2'),
         url('/fonts/conthrax-sb-webfont.woff') format('woff'),
         local("Conthrax");
    font-weight: normal;
    font-style: normal;

    font-display: block; /* wait indefinitely to replace, avoid flashing unstyled text if possible */
}
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap'); /* Didact Gothic serves as a replacement for Futura */


BODY {
    background-color: var(--map-bg);
    /* Can't set it here, too many places assume black    color: var(--ooo-white); */
}
A, .btn-link { color: var(--dc-blue-ligther); }
A:hover, .btn-link:hover { color: var(--dc-green); }
H1, H2 { color: var(--dc-orange); font-family: conthraxsemibold,monospace; }
.navbar { font-family: conthraxsemibold,monospace; }


.rulespage {
    color: var(--dc-text);
    padding: 1em;
    max-width: 1000px; margin: auto; /* centered */
    font-family: 'Didact Gothic',Ubuntu,Candara,Roboto,sans-serif;
    font-size: 120%;
}
.rulespage h2 { font-size: 1.3rem; }
.rulespage code { font-weight: bold; color: inherit; padding: .2rem; background-color: #333; }

.chalmenu-footer {
    color: #5a5a5a;
    text-align: center;
    font-family: 'conthraxsemibold',monospace;
}
.sepbar { padding-left: 1em; padding-right: 1em; }

#thebigdiv {
    /* We want the dot positions to be relative to this div.
     * Apparently the div has to have position: relative or absolute */
    position: relative;

    width:  90vw;  /* base width: fill the screen */
    height: 48vw;  /* same unit as the width, should preserve the ratio */
                   /* chrome-only for now: aspect-ratio: 1678 / 908 */
    min-width:  800px; min-height: 433px;  /* Let's not go too small */
    margin: auto;  /* centered */

    /* The map image, stretched to cover the div exactly */
    background: no-repeat url("/pics/map.jpg");
    background-size: 100% 100%;
}


button.challenge {
    position: absolute;  /* per challenge: top: x% left: y% ... */
    cursor: pointer;
    z-index: 5; /* above the label div, which covers it */

    width: 50px;
    height: 50px;
    background-size: 45px 45px;
    background-repeat: no-repeat;
    opacity: 0.9;

    background-color: rgba(0,0,0,0.80);
    border-radius: 20px;
    border: 3px solid rgba(0,0,0,0.80);

    /*transition: opacity 0.25s ease-in-out 0.25s, width 0.25s ease-in-out 0.25s, height 0.25s ease-in-out 0.25s, z-index 0.25s ease-in-out 0.25s;*/
}
div.chalname {
    position: absolute; z-index: 2;  /* same position as the button, don't cover it. */
    padding-top: 12px; padding-left: 60px; /* This should put the text on its right */
    font-family: conthraxsemibold,monospace;
    color: white;
}
div.chalname label { cursor: pointer; }
button.challenge:active, button.challenge:focus { opacity: 1; }
button.challenge-unsolved { box-shadow: 3px 3px 3px red; }
button.challenge-solved   { box-shadow: 3px 3px 3px green; }
div.chalname-unsolved     { text-shadow: 3px 3px 3px red; }
div.chalname-solved       { text-shadow: 3px 3px 3px green; }

.challenge-hidden { display: none; } /* was: background-color: #202223;*/


.modal-dialog { opacity: 0.9; }
.modal-header { font-family: conthraxsemibold,monospace; background-color: var(--ooo-dark); color: var(--dc-yellow); }
.emojifont { font-family: emoji, sans-serif; }
.modal-title { font-size: 1.8rem; }
.modal-header button { color: white; }
.modal-body { font-family: monospace; background-color: var(--ooo-dark); color: #ddd; font-size: 110%; }
.modal-body code { font-size: 120%; font-weight: bold; color: var(--dc-blue-ligther); }
.modal-body a { font-weight: bold; color: var(--dc-green); }
.modal-body a:hover { color: var(--dc-green-ligther); }
.modal-body ul { list-style-type: disclosure-closed; }
.modal-body li { color: #999; }
.modal-footer { background-color: #304650; color: var(--dc-text); font-family: monospace; } /* the base color and font are used in the login modal status message */
.modal-footer input[type=text] { background-color: #ccc; color: black; font-family: monospace; }
.modal-footer input[type=button] { background-color: var(--dc-red); color: white; font-family: conthraxsemibold,monospace; border-color: var(--dc-red); }
.modal-footer button { font-family: conthraxsemibold,monospace; }
/* btn-secondary */
.already-solved-msg { padding-right: 1em; color: var(--dc-green); }
.tagthingy { text-align: right; margin-top: 0.5em; }
.grantsline { text-align: right; margin-bottom: 1em; margin-top: 0.5em; }
.grantsline .emojifont { padding-left: .3em; padding-right: .3em; font-size: 1.5rem; }
span.tag-boxy-thing { /* from the archive */
    background-color: var(--dc-red-darker);
    /*border: var(--dc-red) 1px solid;*/
    color: var(--dc-text);
    padding: 0.2em 0.4em;
    vertical-align: middle;
    font-size: 80%;
    display: inline-block;
    margin-left: .5rem;
    font-family: conthraxsemibold, monospace;
    margin-bottom: 0.3em;
}

.modal-body { overflow: auto; overflow-wrap: anywhere; }  /* these were already there */
.modal-content { max-height: 80vh; overflow-y: auto; }

table.table { color: var(--dc-text); font-family: conthraxsemibold,monospace; }
table.table-hover tbody tr:hover { color: white; background-color: #fff2; }
table.table td, table.table th { border-color: #888; }
table.table thead th { border-top: none; position: sticky; top: 0; }

.sticky-left { position: sticky; left: 0; } /* Used in the solves table */
td.solves-td-no { color: #666; }
td.teamname-td { max-width: 15em; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; }
.solves-header-emoji { font-family: emoji,sans-serif; /*padding-right: 0.3rem; nbsp should work */ }
.solves-header-points { color: #888; }
.scoreboard-chal-span { padding-right: 1em; font-family: emoji,sans-serif; }

th.scoreboard-th { background-color: var(--map-bg); vertical-align: middle !important; }


.alert-secondary {  /* Computing, sent, etc. */
    color: white;
    font-weight: bold;
    background-color: var(--dc-red);  /* overridden in ChallengeModal if specific messages are received */
    border-color: var(--dc-red);
}
.modal-body .alert-success { background-color: green; border-color: green; color: white; }
.modal-body .alert-danger { border-color: red; background-color: var(--dc-red); color: white; }


.ReactModal__Overlay {
  z-index: 1021 !important;
  background-color: rgba(0,0,0,.65) !important;
}



/* TODO: could we get rid of categories? */
/*
.category:before {
  float: left;
  font-size: 20pt;
  padding-right: 0.25em;
}

.category-crypto:before {
  content: "😭";
}

.category-golf:before {
  content: "🏌️";
}

.category-kernel:before {
  content: "🌰";
}

.category-misc:before {
  content: "🤷";
}

.category-osint:before {
  content: "🕵️";
}

.category-pwn:before {
  content: "🤼";
}

.category-reversing:before {
  content: "🔄";
}

.category-shellcoding:before {
  content: "🐚";
}

.category-web:before {
  content: "🌐";
}

.category-welcoming:before {
  content: "👋";
}
*/

/*
.challenge {
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 18px;
  height: 200px;
  margin: 3px;
  position: relative;
  width: 300px;
}

.challenge-hidden {
  background-color: #202223;
}

.challenge-score {
  bottom: 0px;
  color: white;
  position: absolute;
  right: 0px;
  padding-right: 10px;
}

.challenge-section h2 {
  text-align: center;
  text-transform: capitalize;
}

.challenge-solved {
  background-color: pink;
}

.challenge-title {
  background-color: #00000070;
  bottom: 0px;
  color: white;
  position: absolute;
  width: 300px;
}
*/



/* These seem more important */

.footer-padding {
  padding-bottom: 60px;
}
